import {
  TRANSACTIONS_DATA,
  TRANSACTIONS_ERROR,
  TRANSACTIONS_FETCH,
} from "./constants";
import { Transaction } from "./types";

export interface TransactionsFetch {
  type: typeof TRANSACTIONS_FETCH;
  payload: {
    limit: number;
    currency?: string;
  };
}

export interface TransactionsData {
  type: typeof TRANSACTIONS_DATA;
  payload: Transaction[];
}

export interface TransactionsError {
  type: typeof TRANSACTIONS_ERROR;
}

export type TransactionsAction =
  | TransactionsFetch
  | TransactionsData
  | TransactionsError;

export const transactionsFetch = (
  payload: TransactionsFetch["payload"]
): TransactionsFetch => ({
  type: TRANSACTIONS_FETCH,
  payload,
});

export const transactionsData = (
  payload: TransactionsData["payload"]
): TransactionsData => ({
  type: TRANSACTIONS_DATA,
  payload,
});

export const transactionsError = (): TransactionsError => ({
  type: TRANSACTIONS_ERROR,
});
