import React from "react";
import "./dashboard.scss";

import {
  Status,
  SummaryCard,
  Table,
} from "../../components";

import {
  capitalize,
  localeDate,
  truncateMiddle
} from "../../helpers";
import { Transaction } from "../../modules";
import { Statuses } from "../../api";


export const Dashboard = () => {
  // const dispatch = useDispatch();
  // const copyAddress = () => {
  //   copy("deposit-address");
  //   const payload = { message: ["Address Copied"], type: "success" };
  //   dispatch(alertPush({ ...payload }));
  // };


  const Column = [
    { title: "Date", style: { minWidth: 170 } },
    { title: "Type" },
    { title: "Amount" },
    { title: "Status" },
    { title: "TxID" },
  ];

  const RecentHistories = [
    // {
    //   date: "2023-03-26 20:14:35",
    //   address: "0xf87967f6a701754BD2fe520489Bc1A2D1a8A8f89",
    //   type: "deposit",
    //   amount: "20000",
    //   status: "completed",
    //   conf: 3,
    //   txid: "0x1b13148f109a4c2800621c852408d3af1150dba39c38bc45d4cb39cf513fece9",
    // },
    // {
    //   date: "2023-03-26 20:14:35",
    //   address: "0xf87967f6a701754BD2fe520489Bc1A2D1a8A8f89",
    //   type: "deposit",
    //   amount: "20000",
    //   status: "completed",
    //   conf: 3,
    //   txid: "0x1b13148f109a4c2800621c852408d3af1150dba39c38bc45d4cb39cf513fece9",
    // },
    // {
    //   date: "2023-03-26 20:14:35",
    //   address: "0xf87967f6a701754BD2fe520489Bc1A2D1a8A8f89",
    //   type: "withdraw",
    //   amount: "2000",
    //   fee: 0.01,
    //   status: "processing",
    //   conf: 0,
    //   txid: "0x1b13148f109a4c2800621c852408d3af1150dba39c38bc45d4cb39cf513fece9",
    // },
  ];



  const renderCells = (histories: Transaction[]) =>
    histories.map((history, index) => {
      const { created_at, amount, state, txid, type } = history;
      return (
        <tr key={index}>
          <td>{localeDate(created_at, "fullDate")}</td>
          <td>
            <span className={type}>{capitalize(type)}</span>
          </td>
          <td>{amount}</td>

          <td>
            <a className="l-link" href={"/tx/" + txid}>
              {truncateMiddle(txid, 12)}
            </a>
          </td>
          <td>
            <Status type={Statuses[state]} label={capitalize(state)} />
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="container cx-center dashboard bg-trans">
        <div className="row ac-summary">
          <SummaryCard title="Points" details="0 PTS" desc="Your available points" bgColor="#5B8178" />
          <SummaryCard title="Wallet Balance" details="0 XCI" desc="Your available balance " bgColor="#968A5F" />
          <SummaryCard title="Total Transactions" details="0" desc="Total Deposited/Withdraw" bgColor="#7DA0FA" />


          <SummaryCard title="Completed Task" details="0" desc="Number of task you have completed" bgColor="#4A6ABA" />
          <SummaryCard title="Active Task" details="0 PTS" desc="Your available points" bgColor="#5B8178" />
          <SummaryCard title="Points" details="0" desc="Your total active task" bgColor="#578359" />

        </div>
        <div className="row ac-recent">

          <div className="ac-recent_transaction x100">
            <h4>Recent Transactions</h4>
            <Table column={Column} tableRows={renderCells(RecentHistories)} />
          </div>

          <div className="ac-recent_earn_points">
            <h4>Recent Earned Points</h4>
            <div className="ac-recent_earn_points_wrapper">
              {/* <div className="ac-recent_earn_points_item">
                <div className="icon flex align-center">
                  P
                </div>
                <div className="details">
                  <span>UID49023470934</span>
                  <span>20 PTs</span>
                </div>

              </div>
              <div className="ac-recent_earn_points_item">
                <div className="icon flex align-center">
                  P
                </div>
                <div className="details">
                  <span>UID49023470934</span>
                  <span>20 PTs</span>
                </div>

              </div>
              <div className="ac-recent_earn_points_item">
                <div className="icon flex align-center">
                  P
                </div>
                <div className="details">
                  <span>UID49023470934</span>
                  <span>20 PTs</span>
                </div>

              </div> */}
            </div>


          </div>


        </div>
      </div>
    </>
  );
};
